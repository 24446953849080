import React from 'react'
import kweesha_home_logo from "../../images/kweesha_home_logo.svg";
const KweeshaIcon = () => {
  return (
    <>
 <div className='text-center'><img src={kweesha_home_logo} className='mt-2 ' alt='kweesha_icon'></img></div>
    </>
  )
}

export default KweeshaIcon